export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'identifierSessions',
      displayName: 'menu.files',
      meta: {
        icon: 'vuestic-iconset-files',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'vuestic-iconset-user',
      },
    },
    {
      name: 'identifiers',
      displayName: 'menu.identifiers',
      meta: {
        icon: 'vuestic-iconset-settings',
      },
    },
  ],
}
