import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthLayout from '@/layout/auth-layout.vue'
import AppLayout from '@/layout/app-layout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/pages',
    component: AppLayout,
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'identifierSessions',
        path: 'identifiers',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/identifierSessions/IdentifierSessions.vue'),
      },
      {
        name: 'sessions',
        path: 'identifiers/:id/sessions',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/sessions/Sessions.vue'),
      },
      {
        name: 'grid',
        path: 'identifiers/:id/sessions/grid',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/sessions/Grid.vue'),
      },
      {
        name: 'files',
        path: 'identifiers/:id/sessions/:sessionId/files',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/files/Files.vue'),
      },
      {
        name: 'file',
        path: 'identifiers/:id/sessions/:sessionId/files/:fileId',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/files/File.vue'),
      },
      {
        name: '3dfile',
        path: 'identifiers/:id/sessions/:sessionId/3dfiles/:fileId',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/files/3DFile.vue'),
      },
      {
        name: 'fileGrid',
        path: 'identifiers/:id/sessions/:sessionId/grid',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/files/Grid.vue'),
      },
      {
        name: 'identifiers',
        path: 'identifiers/manage',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/identifiers/Identifiers.vue'),
      },
      {
        name: 'identifier',
        path: 'identifiers/:id/manage',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/identifiers/Identifier.vue'),
      },
      {
        name: 'users',
        path: 'users',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/users/Users.vue'),
      },
      {
        name: 'user',
        path: 'users/:id',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/users/User.vue'),
      },
      {
        name: 'profile',
        path: 'profile',
        meta: {
          requiresAuth: true
        },
        component: () => import('@/pages/admin/profile/Profile.vue'),
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('@/pages/auth/login/Login.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('@/pages/auth/recover-password/RecoverPassword.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})

export default router
