<template>
  <div class="auth-layout row align-content--center">
    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <va-card-content>
            <div class="pa-3 flex-center">
              <div class="pa-3" style="border-right: #e6e9ec solid 1px;">
                <img :src="require('@/assets/Galileo_Half_Light_675x315.png')" height="135">
              </div>
              <div class="pa-3 text-center">
                <h1 style="font-size: 2rem; color: #154ec1; letter-spacing: 0.2rem; margin-top: 0.5rem;">ARMADA&trade;</h1>
              </div>
            </div>
            <va-separator />

            <div class="pa-3">
              <router-view />
            </div>
          </va-card-content>
        </va-card>
      </div>
      <div class="flex-center md-12">
        &copy; 2022-{{ currentYear }} Galileo Group, Inc. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "AuthLayout",
  data() {
    return {
      selectedTabIndex: 0,
      currentYear: moment().format('YYYY')
    };
  },
  computed: {
    tabIndex: {
      set(tabName) {
        this.$router.push({ name: tabName });
      },
      get() {
        return this.$route.name;
      }
    }
  }
};
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;
  background-image: linear-gradient(to right, var(--va-background), var(--va-white));

  &__card {
    width: 100%;
    max-width: 600px;
  }
}
</style>
